<template>
  <div class="wx-login">
    <p>用户登录</p>
    <template v-if="!isChecked">
      <div class="wx-image flex conet j-c">
        <div class="wx-image-img"></div>
      </div>
      <div class="wx-btn flex conet j-c" @click.stop="wxLoginIncident">
        微信登录
      </div>
      <div class="flex conet wx-checked">
        <div
          :class="['checked', checked ? 'active' : '']"
          @click.stop="checkedPitch"
        >
          阅读并同意
        </div>
        <span @click="agreementIncident('USER_AGREEMENT')"
          >《用户使用协议》</span
        >和
        <span @click="agreementIncident('PRIVACY_POLICY')">《隐私政策》</span>
      </div>
    </template>
    <template v-else>
      <div class="wx-container">
        <div id="login_container"></div>
      </div>
      <p class="flex conet j-c wx-text">请使用微信扫描二维码登录</p>
    </template>
  </div>
</template>
<script>
import Utils from '@/utils';
import { loginQrCode, qrCodeStr, userInfo } from '@/api/api';
import store from '@/store';
import { routerPush } from '@/utils/verify';
import { mapGetters } from 'vuex';
var timer, wxJs;
const url =
  'data:text/css;base64,I3N1Yi1mcmFtZS1lcnJvcntkaXNwbGF5OiBub25lICFpbXBvcnRhbnQ7IHdpZHRoOjBweCAhaW1wb3J0YW50O2hlaWdodDowcHggIWltcG9ydGFudDsgYmFja2dyb3VuZDogI2ZmZiAhaW1wb3J0YW50O30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxODBweDt9Ci5zdGF0dXNfaWNvbiwuaWNvbi1nZW5lcmljIHtkaXNwbGF5Om5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9';
export default {
  name: 'WxLgin',
  props: {
    isPupouLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['checked'])
  },
  mounted() {
    wxJs = document.createElement('script');
    wxJs.type = 'text/javascript';
    wxJs.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.body.appendChild(wxJs);
  },
  methods: {
    wxLoginIncident() {
      const _this = this;
      if (!_this.checked) {
        Utils.msgWarning('请勾选《使用协议》和《隐私政策》');
        return;
      } else {
        _this.isChecked = true;
        _this.getloginQrCode();
      }
    },
    getloginQrCode() {
      const _this = this;
      loginQrCode({}).then((res) => {
        if (res.code == 200) {
          new WxLogin({
            self_redirect: true,
            id: 'login_container',
            appid: res.data.appId,
            scope: 'snsapi_login',
            redirect_uri: res.data.callbackUrl,
            state: res.data.key,
            style: 'white',
            href: url
          });
          timer = setInterval(() => {
            setTimeout(_this.qrCodeStrIncident(res.data.key), 0);
          }, 3000);
        }
      });
    },
    qrCodeStrIncident(scene_str) {
      const _this = this;
      const jsonData = {
        key: scene_str
      };
      qrCodeStr(jsonData).then((res) => {
        if (res.code == 200) {
          _this.$store.commit('setUserData', res.data.token);
          _this.$store.commit('setConsumType', true);
          window.clearInterval(timer);
          timer = null;
          _this.$loading();
          setTimeout(() => {
            _this.$loading().close();
            Utils.msgSuccess('登录成功');
            if (_this.isPupouLogin) {
              const jsonDatas = {};
              userInfo(jsonDatas).then((res) => {
                if (res.code == 200) {
                  const { phone, userId, username, headImage } = res.data;
                  store.commit('setUserwebImgUrl', headImage);
                  store.commit('setUserwebUsername', username);
                  store.commit('setUserwebUserId', userId);
                  store.commit('setUserwebPhone', phone);
                  _this.$emit('qrCode', { code: 200, msg: '扫码成功' });
                  if (!phone) {
                    _this.$phonePopup((res) => {});
                  }
                }
              });
            }
          }, 1500);
        } else if (res.code == 4055) {
          window.clearInterval(timer);
          timer = null;
          _this.getloginQrCode();
        }
      });
    },
    checkedPitch() {
      const _this = this;
      _this.$store.commit('isChecked', _this.checked);
    },
    agreementIncident(type) {
      const _this = this;
      if (_this.isPupouLogin) {
        _this.$emit('qrCode', { code: 2001, type: type });
      } else {
        routerPush(_this, '/agreement', {
          type: type,
          path: _this.$route.path
        });
      }
      _this.$store.commit('isChecked', false);
    }
  },
  beforeDestroy() {
    document.body.removeChild(wxJs);
    window.clearInterval(timer);
    timer = null;
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.wx {
  &-login {
    box-sizing: border-box;
    width: 100%;
  }
  &-image {
    width: 110px;
    height: 110px;
    margin: 37px auto 50px auto;
    border: 1px solid @--color-green;
    border-radius: 50%;
    &-img {
      width: 74px;
      height: 60px;
      background: url("~@/assets/images/home/wx.png") no-repeat 0px 0px;
    }
  }
  &-btn {
    width: 100%;
    border-radius: 20px;
    background: @--color-primary;
    border: 1px solid @--color-primary;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF !important;
    height: 40px;
    cursor: pointer;
    margin-bottom: 20px;
    &:hover {
      background: @--color-primary-hover;
      border: 1px solid @--color-primary-hover;
    }
  }
  &-checked {
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-888888;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .checked {
      height: 20px;
      box-sizing: border-box;
      padding-left: 25px;
      background: url("~@/assets/images/home/unselected.png") no-repeat 0px 0px;
      line-height: 18px;
      cursor: pointer;
      &.active {
        background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px 0px;
      }
    }
    span {
      color: @TEXT-COLOR-5090FF;
      cursor: pointer;
    }
  }
  &-container {
    width: 210px;
    height: 220px;
    margin: 20px auto 20px auto;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    /deep/iframe {
      width: 200px !important;
      height: 200px !important;
      cursor: pointer !important;
    }
    &-text {
      .fontSize(@TEXT-SIZE-12);
      color: @TEXT-COLOR-888888;
    }
  }
}
</style>