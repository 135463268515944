<template>
  <nav class="container header-container">
    <div class="container-fluid clear">
      <div class="title fl clear">
        <div class="img fl"></div>
        <div class="fl">南方研学基地</div>
      </div>
      <div class="header-right fl">
        <ul>
          <li
            ref="gatherLi"
            v-for="(itme, index) in headerList"
            :key="index"
            :class="[navigation.index == index ? 'active' : '']"
            @click="scrollCutNavigation(itme, index)"
          >
            {{ itme.title }}
          </li>
          <li
            @click="toWhy"
          >
            <img src="../../assets/images/why_logo.png"
                  style="vertical-align: middle;"/>
            <span style="vertical-align: middle;">越秀文化云</span>
          </li>
          <div
            class="scroll-sliding"
            :style="{ left: navigation.left + 'px', width: width + 'px' }"
          ></div>
        </ul>
      </div>
      <!-- 搜索 -->
      <div v-if="consumType" class="fr header-btn">
        <div class="clear header-btn-all">
          <div class="fl header-btn-portrait" @click="personageSkip">
            <img :src="webImgUrls" alt="" />
          </div>
          <div
            :class="['text', 'fl', navigation.index == -1 ? 'active' : '']"
            @click="personageSkip"
          >
            个人中心
          </div>
          <div class="fl btn" @click.stop="emptyIncident($event, 'quit')">
            退出
          </div>
        </div>
      </div>
      <div class="fr header-input">
        <div
          :class="[
            'header-input-fr',
            show && searchValueList.length > 0 ? 'active' : '',
          ]"
        >
          <input
            class="inputVal"
            type="text"
            placeholder="输入课程标题"
            ref="inputVal"
            @focus="searchValueIncident($event, 'focus')"
            @blur="searchValueIncident($event, 'blur')"
            maxlength="24"
            @keyup.13="searchIncident"
            v-model="searchValue"
          />
          <span @click.stop="searchIncident"></span>
        </div>
        <!-- 历史记录 -->
        <div
          class="header-input-show"
          @mouseover="
            () => {
              this.popupMouseover = true;
            }
          "
          @mouseleave="
            () => {
              this.popupMouseover = false;
            }
          "
          v-show="show && searchValueList.length > 0"
        >
          <div
            class="bor"
            v-for="(itme, index) in searchValueList"
            :key="index"
            @click.stop="searchValueIncident(itme, 'click')"
          >
            {{ itme }}
          </div>
          <div
            class="clear-record flex conet j-c"
            @click.stop="emptyIncident($event, 'empty')"
          >
            清空搜索记录
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import Utils from '@/utils';
import HEADER_LIST from '@/config/header';
import storage from 'good-storage';
import { mapGetters } from 'vuex';
import { saveSearch } from '@/assets/js/seven.js';
import { pupopStatus } from '@/config/pupupUtils';
import { logout } from '@/api/api';
import { routerPush } from '@/utils/verify';
const ActiveType = { Home: 0, Curriculum: 1, Learn: 2 };
export default {
  name: 'YanHeader',
  data() {
    return {
      headerList: HEADER_LIST, // 导航数组
      width: 0, // li 宽
      width_offset_x: 0, // li 偏移
      searchValue: '', // 截取搜索url
      search: '', // 搜索输入
      show: false, // 是否显示历史搜索
      popupMouseover: false, // 鼠标移入移出
      searchValueList: [] // 历史搜索记录数组
    };
  },
  computed: {
    ...mapGetters(['consumType', 'webImgUrls']),
    navigation() {
      const { name } = this.$route;
      const homeList = ['home', null, 'train', 'trainMore', 'search'];
      const curriculumList = [
        'curriculum',
        'courseDetails',
        'broadcast',
        'broadxgplayer'
      ];
      const learnList = ['learn', 'evaluate', 'certificate'];
      if (homeList.includes(`${name}`)) {
        return {
          index: ActiveType.Home,
          left: 0
        };
      } else if (curriculumList.includes(`${name}`)) {
        return {
          index: ActiveType.Curriculum,
          left: this.width + 15
        };
      } else if (learnList.includes(`${name}`)) {
        return {
          index: ActiveType.Learn,
          left: this.width_offset_x + this.width + 15
        };
      } else {
        return {
          index: -1,
          left: 1210
        };
      }
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.name == 'courseDetails' && oldVal.name == 'generationApply') {
        this.$store.commit('setModuleState', {
          isGenerationApply: true
        });
      } else {
        this.$store.commit('setModuleState', {
          isGenerationApply: false
        });
      }
      this.search = val.query.searchValue;
      if (val.name !== 'search') {
        this.searchValue = '';
        this.show = false;
      }
      const list = storage.get('_search_');
      this.searchValueList = list || [];
    },
    show() {
      const list = storage.get('_search_');
      this.searchValueList = list || [];
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const _this = this;
      _this.width = _this.$refs.gatherLi[0].clientWidth;
      _this.width_offset_x = _this.$refs.gatherLi[1].clientWidth;
      if (localStorage.getItem('H5_LOGIN_TYPE')) {
        _this.$store.commit('setConsumType', true);
      } else {
        _this.$store.commit('setConsumType', false);
      }
    },
    scrollCutNavigation(itme, index) {
      const _this = this;
      if (!itme.whether) {
        if (!_this.consumType) {
          const obj = {
            title: pupopStatus(1)[1],
            leftBtn: pupopStatus(2)[0],
            rightBtn: pupopStatus(3)[0]
          };
          _this.$trainPopup(obj).then((res) => {
            if (res.code == 200) {
              if (_this.$route.path != '/') {
                routerPush(_this, '/');
              }
            }
          });
          return;
        }
      }
      // 路由相同的时候
      if (itme.path != _this.$route.path) {
        routerPush(_this, itme.path);
      }
    },
    /**
     * @focus @blur @click
     * 获取，失去焦点，点击li事件
     * */
    searchValueIncident(itme, focus) {
      switch (focus) {
        case 'focus':
          this.show = true;
          break;
        case 'blur':
          this.$nextTick(() => {
            if (!this.popupMouseover) {
              this.show = false;
            }
          });
          break;
        case 'click':
          if (this.search == itme) {
            this.searchValue = this.search;
            this.show = false;
            return;
          }
          this.searchValue = itme;
          this.show = false;
          const { searchValue } = this.$route.query;
          if (this.$route.path != '/search') {
            routerPush(this, '/search', { searchValue: this.searchValue });
          } else {
            this.replaceRouter(itme, searchValue);
          }
          break;
        default:
          break;
      }
    },
    // 路由替换
    replaceRouter(date, parameter) {
      const _this = this;
      if (parameter == date) {
        return;
      }
      _this.$router.replace({
        path: _this.$route.path,
        query: { ..._this.$route.query, searchValue: date }
      });
    },
    // 键盘事件 点击搜索
    searchIncident() {
      const _this = this;
      if (_this.search == _this.searchValue) return;
      if (_this.searchValue == '') return;
      routerPush(_this, '/search', { searchValue: _this.searchValue });
      // 保存在缓存历史
      saveSearch(_this.searchValue);
      _this.$nextTick(() => {
        _this.$refs.inputVal.blur();
        _this.searchValue = '';
      });
    },
    // 个人中心跳转
    personageSkip() {
      const _this = this;
      if (_this.$route.path !== '/personage') {
        routerPush(_this, '/personage');
      }
    },
    emptyIncident(e, method) {
      const _this = this;
      const obj = {
        title: pupopStatus(1)[2],
        leftBtn: pupopStatus(2)[0],
        rightBtn: pupopStatus(3)[0]
      };
      switch (method) {
        case 'quit':
          obj.title = pupopStatus(1)[0];
          _this.$trainPopup(obj).then((res) => {
            _this.$loading();
            setTimeout(() => {
              _this.$store.commit('setConsumType', '');
              _this.$loading().close();
              logout({}).then((r) => {
                Utils.msgSuccess('退出成功');
              });
              localStorage.clear();
              if (_this.$route.path != '/') {
                routerPush(_this, '/');
              }
            }, 1500);
          });
          break;
        case 'empty':
          _this.$trainPopup(obj).then((res) => {
            _this.$loading();
            setTimeout(() => {
              localStorage.removeItem('_search_');
              _this.$loading().close();
              Utils.msgSuccess('清空搜索记录成功');
              _this.show = false;
            }, 1500);
          });
          break;
        default:
          break;
      }
    },
    toWhy() {
      window.open('http://www.iwhyun.com/')
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.header {
  &-container {
    height: 80px;
    box-sizing: border-box;
    .border-direction(bottom, 1px, solid, @TEXT-BG-E6E6E6);
    .title {
      height: 50px;
      line-height: 50px;
      .fontSize(@TEXT-SIZE-28);
      font-weight: 400;
      padding-top: 15px;
      box-sizing: border-box;
    }
    .img {
      background: url("~@/assets/images/logo.png") no-repeat 0px 0px;
      height: 50px;
      width: 50px;
      margin-right: 15px;
    }
  }
  &-right {
    box-sizing: border-box;
    padding-left: 60px;
    ul {
      position: relative;
      line-height: 80px;
      overflow: hidden;
      li {
        display: inline-block;
        padding: 0 25px;
        .fontSize(@TEXT-SIZE-16);
        text-align: center;
        cursor: pointer;
        &.active {
          color: @--color-primary;
          font-weight: bold;
          transition: all 0.2s linear;
        }
      }
      .scroll-sliding {
        position: absolute;
        bottom: 1px;
        background: @--color-primary;
        height: 3px;
        transition: all 0.2s linear;
      }
    }
  }
  &-btn {
    line-height: 80px;
    height: 80px;
    margin-left: 20px;
    cursor: pointer;
    &-all {
      height: 80px;
      position: relative;
      .text {
        margin-left: 38px;
        margin-right: 70px;
        .fontSize(@TEXT-SIZE-16);
        &.active {
          color: @--color-primary;
        }
      }
      .btn {
        width: 50px;
        height: 24px;
        border: 1px solid @TEXT-BG-E6E6E6;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
        line-height: 22px;
        text-align: center;
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-12);
        border-radius: 20px;
        &:hover {
          background: @TEXT-BG-E6E6E6;
        }
      }
    }
    &-portrait {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -15px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-input {
    height: 80px;
    width: 200px;
    position: relative;
    &-show {
      position: absolute;
      width: 100%;
      height: auto;
      top: 59px;
      left: 0;
      border: 1px solid @--color-primary;
      border-radius: 20px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: #fff;
      z-index: 1000;
      border-top: none;
      padding: 0 10px;
      box-sizing: border-box;
      overflow: hidden;
      transition: all 0.2s linear;
      .bor {
        height: 38px;
        line-height: 38px;
        .fontSize(@TEXT-SIZE-14);
        color: @TEXT-COLOR-888888;
        border-bottom: 1px solid @TEXT-BG-E6E6E6;
        box-sizing: border-box;
        padding-left: 10px;
        cursor: pointer;
        overflow: hidden;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          color: @--color-primary;
        }
      }
      .clear-record {
        height: 38px;
        .fontSize(@TEXT-SIZE-12);
        color: @TEXT-COLOR-888888;
        cursor: pointer;
        &:hover {
          color: @--color-primary;
        }
      }
    }
    &-fr {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -19px;
      border: 1px solid @TEXT-COLOR-E6E6E6;
      width: 100%;
      height: 38px;
      overflow: hidden;
      border-radius: 20px;
      &:hover{
        border-color: @--color-primary;
      }
      input {
        .fontSize(@TEXT-SIZE-16);
        color: @TEXT-COLOR-888888;
        width: 100%;
        height: 98%;
        border-radius: 20px;
        padding: 0 30px 0 20px;
      }
      span {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url("~@/assets/images/home/search.png") no-repeat 0px 0px;
        position: absolute;
        top: 50%;
        margin-top: -9px;
        right: 15px;
        cursor: pointer;
      }
      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: all 0.2s linear;
      }
    }
  }
}
</style>