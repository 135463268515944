<template>
  <!-- 弹出扫码登录 -->
  <div class="qr-popup" v-if="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="beforClose"
      width="360px"
    >
      <wx-login :isPupouLogin="true" @qrCode="qrCode"></wx-login>
    </el-dialog>
  </div>
</template>
<script>
import WxLogin from '@/components/wxLogin/index';
export default {
  name: 'QrCode',
  components: {
    WxLogin
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    beforClose() {
      this.dialogVisible = false;
    },
    qrCode(res) {
      this.dialogVisible = false;
      if (res.code === 200) {
        this.$emit('qrCode', { code: 200, msg: '扫码成功' });
      } else if (res.code === 2001) {
        this.$emit('agreementIncident', res.type);
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.qr-popup {
  box-sizing: border-box;
  color: @TEXT-COLOR-363636;
  /deep/.el-dialog {
    border-radius: 10px;
    height: 380px;
    overflow: hidden;
    margin-top: 200px !important;
  }
  /deep/.el-dialog__body {
    padding: 0 30px;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    overflow: hidden;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
    color: @--color-primary;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close {
    color: @TEXT-COLOR-363636;
  }
  /deep/.el-dialog__header {
    font-size: 20px;
    font-weight: bold;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>