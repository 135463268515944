import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '../store';
Vue.use(VueRouter);
const routes = [
  // {
  //   path: '',
  //   meta: { requireAuth: false },
  //   redirect: '',
  //   component: (resolve) => { require(['@/views/home/index'], resolve) }
  // },
  // 首页
  {
    path: '',
    name: 'home',
    meta: { requireAuth: false },
    component: (resolve) => { require(['@/views/home/index'], resolve) }
  },
  {
    path: '/webView',
    name: 'webView',
    meta: { requireAuth: false },
    component: (resolve) => { require(['@/views/webView/index'], resolve) }
  },
  {
    path: '/trainMore',
    name: 'trainMore',
    meta: { requireAuth: false },
    component: (resolve) => { /* 培训更多 */ require(['@/views/home/trainMore/trainMore'], resolve) }
  },
  {
    path: '/train',
    name: 'train',
    meta: { requireAuth: false },
    component: (resolve) => { /* 通知 */ require(['@/views/home/train/train'], resolve) }
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    meta: { requireAuth: false },
    component: (resolve) => { /* 全部课程 */ require(['@/views/curriculum/index'], resolve) }
  },
  {
    path: '/generationApply',
    name: 'generationApply',
    meta: { requireAuth: true },
    component: (resolve) => { /* 代报名列表 */ require(['@/views/curriculum/generationApply/index'], resolve) }
  },
  {
    path: '/courseDetails',
    name: 'courseDetails',
    meta: { requireAuth: false },
    component: (resolve) => { /* 全部课程-详情 */ require(['@/views/curriculum/courseDetails/index'], resolve) }
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    meta: { requireAuth: true },
    component: (resolve) => { /* 进入学习-直播 */ require(['@/views/curriculum/broadcast/index'], resolve) }
  },
  {
    path: '/broadxgplayer',
    name: 'broadxgplayer',
    meta: { requireAuth: true },
    component: (resolve) => { /* 进入学习-回放直播 */ require(['@/views/curriculum/broadxgplayer/index'], resolve) }
  },
  {
    path: '/learn',
    name: 'learn',
    meta: { requireAuth: true },
    component: (resolve) => { /* 我的学习 */ require(['@/views/learn/index'], resolve) }
  },
  {
    path: '/certificate',
    name: 'certificate',
    meta: { requireAuth: true },
    component: (resolve) => { /* 评价 */ require(['@/views/learn/certificate/index'], resolve) }
  },
  {
    path: '/personage',
    name: 'personage',
    meta: { requireAuth: true },
    component: (resolve) => { /* 个人中心 */ require(['@/views/personage/index'], resolve) }
  },
  {
    path: '/manage',
    name: 'manage',
    meta: { requireAuth: true },
    component: (resolve) => { /* 设置管理 */ require(['@/views/personage/manage/index'], resolve) }
  },
  {
    path: '/search',
    name: 'search',
    meta: { requireAuth: false },
    component: (resolve) => { /* 搜索页面 */ require(['@/views/search/index'], resolve) }
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      requireAuth: false,
      replace: true
    },
    component: (resolve) => { /* 用户协议*/ require(['@/views/agreement/index'], resolve) }
  },
  {
    path: '/loginService',
    name: 'loginService',
    meta: {
      requireAuth: false,
      replace: true
    },
    component: (resolve) => { require(['@/views/loginService/index'], resolve) }
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  // if (to.meta.requireAuth && localStorage.getItem('H5_LOGIN_TYPE')) {
  //   next()
  // } else if (to.meta.requireAuth && !localStorage.getItem('H5_LOGIN_TYPE')) {
  //   next({
  //     path: '/home', // 登录页
  //     query: { redirect: to.fullPath }
  //   })
  // } else {
  next()
  // }
});
export default router;
